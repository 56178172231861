import React from "react";
import "./Location.css";

export default function Location() {
  let locations = ["Maputo", "Sofala", "Manica", "Nampula", "Tete"],
   
    current_tab = 0;
  const toggleLocation = (value = "Maputo") => {
let  active_tabs = document.querySelectorAll(".location__tabs"),
icons = document.querySelectorAll(".fa"),
btn__location = document.querySelectorAll(".btn__location");
    document.querySelector("b.location").innerText = value;

    active_tabs[current_tab].classList.remove("active");
    btn__location[current_tab].classList.remove("active");

   
    icons[current_tab].classList.remove("fa-folder-open");
    icons[current_tab].classList.add("fa-folder");

    current_tab = locations.indexOf(value);

    active_tabs[current_tab].classList.add("active");
    btn__location[current_tab].classList.add("active");

    icons[current_tab].classList.add("fa-folder-open");
    icons[current_tab].classList.remove("fa-folder");
  };

  return (
    <div id="location">
      <div className="container text-center">
        <header>
          <h1 className="text-center">Estâncias</h1>
          <hr />
          <br />
        </header>
        <div className="w-100 d-flex justify-content-center ">
          <div className="d-flex mt-3 justify-content-between flex-wrap">
            <button
              onClick={() => {
                toggleLocation("Maputo");
              }}
              className="mb-2 btn__location active"
            >
              Maputo{" "} <i className="fa fa-folder-open"></i>
            </button>
            <button
              onClick={() => {
                toggleLocation("Sofala");
              }}
              className="mb-2 btn__location"
            >
              Sofala<i className="fa fa-folder"></i>
            </button>
            <button
              onClick={() => {
                toggleLocation("Manica");
              }}
              className="mb-2 btn__location"
            >
              Manica<i className="fa fa-folder"></i>
            </button>
            <button
              onClick={() => {
                toggleLocation("Nampula");
              }}
              className="mb-2 btn__location"
            >
              Nampula <i className="fa fa-folder"></i>
            </button>
            <button
              onClick={() => {
                toggleLocation("Tete");
              }}
              className="mb-2 btn__location"
            >
              Tete <i className="fa fa-folder"></i>
            </button>
          </div>
        </div>

        <p className="mt-3 mb-4">
          Em <b className="location">Maputo</b>, estamos em :
        </p>
        <div className="row">
          <div className="col-md-12 row location__tabs active">
            <div className="col-md-3 mb-3">
              <div>
                <h4>
                  Km4 <hr />
                </h4>
                <span>Ressano Garçia</span>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <h4>
                  Aereporto <hr />
                </h4>
                <span>Terminal Internacional Aérea</span>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <h4>
                  Multimodal <hr />
                </h4>
                <span>Av. das FPLM, CFM</span>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <h4>
                  TIMAR <hr />
                </h4>
                <span>Terminal Internaçional Maritma (Porto)</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 row location__tabs">
            <div className="col-md-6 mb-3">
              <div>
                <h4>
                  TIAR <hr />
                </h4>
                <span>Terminal Internacional Aérea</span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div>
                <h4>
                  TIMAR <hr />
                </h4>
                <span>Terminal Internaçional Maritma (Porto)</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 row location__tabs">
            <div className="col-md-12 mb-3">
              <div>
                <h4>
                  Machipanda <hr />
                </h4>
                <span> </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 row location__tabs">
            <div className="col-md-12 mb-3">
              <div>
                <h4>
                  TIMAR <hr />
                </h4>
                <span>Terminal Internaçional Maritma (Porto)</span>
              </div>
            </div>
          </div>
          <div className="col-md-12 row location__tabs">
            <div className="col-md-6 mb-3">
              <div>
                <h4>
                  Aeroporto <hr />
                </h4>
                <span>Terminal Internaçional Aérea </span>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div>
                <h4>
                  Tete Sede <hr />
                </h4>
                <span>Matema </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
