import React from 'react'
import './Button.css';

export default function Button({text,}) {
    return (
        <div>
            <button> {text} </button>
        </div>
    )
}
