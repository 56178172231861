import React from "react";
import Home from "./components/pages/Home";
import './app.css';
const App = () => {
  return (
    <>
      <Home/>
    </>
  );
};

export default App;
