import React from "react";
import "./Contact.css";
import TextArea from "../TextArea";
import Button from "../Button";
import Input from "../Input";

export default function Contact() {
  let contactInfo ={
    name:'',
    phone:0,
    email:'',
    message:''
  }

  console.log( contactInfo)
  return (
    <div id="contact">
      <div className="container row mb-5 pb-5 mt-5 pt-5">
        <div className="col-md-6  mb-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224.2207671652656!2d32.590348650234276!3d-25.95057826471788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee69a4396aa2367%3A0x23bfd27594111bdd!2sAv.%20Vladimir%20Lenine%2C%20Maputo%2C%20Mozambique!5e0!3m2!1sen!2spt!4v1642665481740!5m2!1sen!2spt"
            width={"100%"}
            height={"450"}
            style={{ border: "0" }}
          ></iframe>
        </div>
        <div className="col-md-6 ">
          <div>
            <header className="contact__info">
              <h2>Contacte-nos</h2>
              <p className="text-danger">Esperamos por sí</p>
              <div>
                Telef : (+258) 21 31 43 73/74 <br />
                E-mail : comercial@sodel.co.mz
              </div>
            </header>
            <form>
              <Input placeholder="Seu Nome"  />
              <Input placeholder="Telefone" />
              <Input placeholder="E-mail" />
              <TextArea placeholder="Mensagem" />
              <br />
              <Button text="Enviar Mensagem" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
