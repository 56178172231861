import React from "react";
import "./About.css";

export default function About() {
  return (
    <section id="about">
        <div className="container pt-5 pb-5">
          <header className="text-center  mb-5">
            <h1 className="text-center">Sodel</h1>
            <hr />
            <br />
            <span>
              A SODEL tem como principal objectivo a satisfação do cliente ao
              seu mais alto nível, pondo à <br />
              disposição do mesmo serviços da mais alta qualidade e equipes
              dinâmicas.
            </span>
          </header>
          <div className="row mt-5">
              < div className="col-md-6 text-center ">
                    <h2 className="subtitle">Visão</h2><br/>
                    <p>Acreditamos numa liderança e crescimento sustentável, trabalhando no sentido de obter as melhores soluções para a satisfação dos nossos clientes.</p>
              </div>
              < div className="col-md-6 text-center ">
                    <h2 className="subtitle">Missão</h2><br/>
                    <p>Satisfação do cliente oferecendo serviços de qualidade certificada e a preços competitivos.</p>
              </div>
          </div>
        </div>
    </section>
  );
}
