import React, { useState, useEffect } from "react";
import "./NavBar.css";
import logo from "../../assets/images/logo.png";

const Navbar = () => {
  let linkIndex = 0;
  const toggleMobileMenu = () => {
    document.querySelector(".header__navbar nav").classList.toggle("open");
  };
  function closeMobileMenu() {
    document.querySelector(".header__navbar nav").classList.remove("open");
  }
  window.addEventListener("scroll", () => {
    document
      .querySelector(".header__navbar")
      .classList.toggle("stick", window.scrollY > 0);
  });
  function activeLink() {
    document.querySelectorAll("ul li a").forEach((element, index) => {
      element.classList.remove("active");
      if (index == linkIndex) {
        element.classList.add("active");
      }
    });
  }

  window.document.addEventListener('load',()=>{
    document.querySelector('.mobile__menu').addEventListener('click', ()=>{
      console.log(121212)
    })
  })
 

  function setActiveLink(link) {
    linkIndex = link;
    activeLink();
  }
  useEffect(() => {
    activeLink();
  }, []);
  return (
    <section className="header">
      <div className=" header__navbar ">
        <div className="logo">
          <img src={logo} />
        </div>
        <button className="mobile__menu" onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav>
          <ul>
            <li>
              <a
                onClick={closeMobileMenu}
                onClick={() => {
                  setActiveLink(0);
                }}
                href="#home"
              >
                Inicio
              </a>
            </li>
            <li>
              <a
                onClick={closeMobileMenu}
                onClick={() => {
                  setActiveLink(1);
                }}
                href="#about"
              >
                Sobre
              </a>
            </li>
            <li>
              <a
                onClick={closeMobileMenu}
                onClick={() => {
                  setActiveLink(2);
                }}
                href="#service"
              >
                Serviços
              </a>
            </li>
            <li>
              <a
                onClick={closeMobileMenu}
                onClick={() => {
                  setActiveLink(3);
                }}
                href="#location"
              >
                Estancias
              </a>
            </li>
            <li>
              <a
                onClick={closeMobileMenu}
                onClick={() => {
                  setActiveLink(4);
                }}
                href="#contact"
              >
                Contacte-nos
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Navbar;
