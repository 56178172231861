import React from 'react'
import './Service.css';

export default function Service() {
    return (
        <div id='service'> 
             <div className='container'>
                    <header className='text-center header__content'>
                        <h1 className='text-center'>Nossos Serviços</h1>
                        <hr/><br/>
                       <h2 className="subtitle">Desembaraço aduaneiro</h2><br/>
                    </header>
                    <div className='text-center'>
                        <p>O parceiro certo nos procedimentos aduaneiros da sua empresa. Estamos presentes nos portos, aeroportos e postos fronteiriços de Moçambique, com nossos profissionais qualificados para um atendimento personalizado</p>
                    </div>
                </div>   
        </div>
    )
}
