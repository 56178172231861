import React from "react";
import "./Home.css";
import NavBar from "../navbar/NavBar";
import HeroSection from "../hero/Hero";
import About from "../about/About";
import Service from "../service/Service";
import Location from "../location/Location";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";

export default function Home() {
  function goToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  window.document.addEventListener("scroll", () => {
    if (
      document.documentElement.scrollTop > 500 ||
      document.body.scrollTop > 500
    ) {
      document.querySelector(".goToTop").style.display = "block";
    } else {
      document.querySelector(".goToTop").style.display = "none";
    }
  });

  return (
    <div className="wrappper">
      <NavBar />
      <div id="home">
        <HeroSection />
      </div>
      <About />
      <Service />
      <Location />
      <Contact />
      <Footer />
      <div className="goToTop" onClick={goToTop}>
        TOP
      </div>
    </div>
  );
}
