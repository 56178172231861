import React from "react";
import "./Hero.css";
import bg_1 from "../../assets/images/bg.JPG";

const Herosection = () => {
  return (
    <>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active ">
            <img src={bg_1} className="d-block w-100" alt="..." />
            <div className="caption">
              <header className="text-center">
                <h1 className="text-center" style={{fontSize:"6rem",opacity:'.7'}}>Sodel</h1>
              </header>
              <h1 style={{fontSize:"3rem"}} className="mb-3">Sociedade de Despachos, Limitada</h1>
              <p>Operamos no mercado nacional a mais de 14 anos. </p>
            </div>
          </div>
          <div className="carousel-item ">
            <img
              src="https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1024&q=60"
              className="d-block w-100"
              alt="..."
            />

            <div className="caption">
              <h1 className="mb-3">Nosso Compromisso</h1>
              <p>
                Somos especializados no trâmite de todo o processo aduaneiro a
                nível nacional
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden"></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden"></span>
        </button>
      </div>
    </>
  );
};

export default Herosection;
