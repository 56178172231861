import React from "react";
import "./Footer.css";
import Logo from "../../assets/images/footer__logo.png";
export default function Footer() {
  let date = 2022;

  return (
    <div className="section__footer">
      <div className="container">
        <div className="footer__containner">
          <div className="footer__logo">
            <img src={Logo} />
            <p>  {date} © Todos direitos reservados</p>
          </div>
          <div className="footer__adress">
            <h2>Endereço</h2>
            <p>
              Av. Vladimir Lenine N.170, Millennium Park
              <br />
              Moçambique - Maputo
            </p>
          </div>
          <div className="footer__contact">
            <h2>Contacte-nos</h2>
            <p>
              comercial@sodel.co.mz <br />( +258 ) 21 31 43 73 / 74
            </p>
          </div>
        </div>
      </div>
     
    </div>
  );
}
